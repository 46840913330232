import { Atom } from ":mods";

export const ROUTES = {
  foundation: (id?: string | number) => `/foundation/showcase/${id ?? ":id"}`,
};

export const ANCHORES = {
  side: {
    admin: {
      foundation: {
        title: "Edit Showcase",
        icon: {
          idle: "icon-local-nav-side:showcase-idle",
          active: "icon-local-nav-side:showcase-active",
        },
        to: ROUTES.foundation(1),
      } as Atom.Route.model.AnchorItem,
    },
    student: {
      foundation: {
        title: "Showcase",
        icon: {
          idle: "icon-local-nav-side:showcase-idle",
          active: "icon-local-nav-side:showcase-active",
        },
        to: ROUTES.foundation(1),
      } as Atom.Route.model.AnchorItem,
    },
  },
};
